const state = () => {
  return {
    menu: [
      {
        icon: 'CreditCardIcon',
        pageName: 'report01',
        title: 'RTD Lucky Overview'
      },
      {
        icon: 'CreditCardIcon',
        pageName: 'report02',
        title: 'RTD Lucky Trend'
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
