<template>
  <div class="w-full sm:w-auto flex mt-1 sm:mt-1 mb-2" style="position: relative; float: right;">
                  <div class="flex mt-5">
                    <a href="javascript:;" data-toggle="modal" data-target="#chooseWS"  class="w-full mr-4 cursor-pointer">
                       <div class="w-6 h-6 flex-none image-fit">
                        <img
                          :src="require(`@/assets/images/tabIcon/Data Export icon.png`)"
                        />
                      </div>
                    </a>
                    <button
                     title="Download Image"  v-on:click="exportImage"
                      class="w-full mr-4 cursor-pointer"
                    >
                      <div class="w-6 h-6 flex-none image-fit">
                        <img
                          :src="require(`@/assets/images/tabIcon/Image icon.png`)"
                        />
                      </div>
                    </button>
                    <button
                       title="Download PDF" v-on:click="exportToPDF"
                      class="w-full mr-4 cursor-pointer"
                    >
                      <div class="w-6 h-6 flex-none image-fit">
                        <img
                          :src="require(`@/assets/images/tabIcon/PDF icon.png`)"
                        />
                      </div>
                    </button>
                    <button
                      title="Download Power point"  v-on:click="exportPowerPoint"
                      class="w-full mr-4 cursor-pointer"
                    >
                      <div class="w-6 h-6 flex-none image-fit">
                        <img
                          :src="require(`@/assets/images/tabIcon/powerpoint.svg`)"
                        />
                      </div>
                    </button>
                  </div>
                </div>
    <!-- END: Account Menu -->
    <div ref="tableau"></div>
    <div ref="shopRegByStore" style="display:none;">
    </div>
    <div ref="shopStatByStore" style="display:none;">
    </div>
    <div ref="shopStatus" style="display:none;">
    </div>
    <div ref="cumulativeReg" style="display:none;">
    </div>
    <div ref="cumulativeParShop" style="display:none;">
    </div>
    <div ref="cumulativeParShopper" style="display:none;">
    </div>

<!-- END: Modal Toggle -->
<!-- BEGIN: Modal Content -->
<div id="chooseWS" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                    Choose Worksheet
                </h2>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body grid grid-cols-12 gap-20 gap-y-3">
                <div class="col-span-12 sm:col-span-6">
                    <label for="modal-form-6" class="form-label">ข้อมูลจาก Worksheet</label>
                    <select v-model="wsSelected" id="modal-form-6" class="form-select">
                        <option value="ShopRegByStore">Shop Register by Store</option>
                        <option value="ShopStatByStore">Shop Status by Store</option>
                        <option value="ShopStatus">Shop Status</option>
                        <option value="CumuReg">Cumulative Registration</option>
                        <option value="CumuParShop">Cumulative Participation (Shop)</option>
                        <option value="CumuParStore">Cumulative Participation (Shopper)</option>
                    </select>
                </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer text-right">
                <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-20 mr-1">
                    Close
                </button>
                <button type="button" class="btn btn-primary w-30"   v-on:click="exportExcelData">
                    Export Data
                </button>
            </div>
            <!-- END: Modal Footer -->
        </div>
    </div>
</div>
<!-- END: Modal Content -->
</template>

<script>

export default {
  name: 'Tableau Report 2',
  data() {
    return {
      wsSelected: '',
      url: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/RTDLuckyTrend?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:toolbar=no&:mobile=yes',
      urlWB: {
        shopRegByStore: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/ShopRegisterByStore?:origin=card_share_link&:embed=n',
        shopStatByStore: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/ShopStatusByStore?:origin=card_share_link&:embed=n',
        shopStatus: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/ShopStatus?:origin=card_share_link&:embed=n',
        cumulativeReg: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/CumulativeRegistration?:origin=card_share_link&:embed=n',
        cumulativeParShop: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/CumulativeParticipationshop?:origin=card_share_link&:embed=n',
        cumulativeParShopper: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/CumulativeParticipationshopper?:origin=card_share_link&:embed=n'
      },
      options: {
        hideTabs: true,
        onFirstInteractive: function() {
          console.log('Run this code when the viz has finished loading.')
        }
      }
    }
  },
  mounted: function() {
    this.initViz()
  },
  methods: {
    initViz: function() {
      this.viz = new tableau.Viz(this.$refs.tableau, this.url, this.options)
      this.visShopRegByStore = new tableau.Viz(this.$refs.shopRegByStore, this.urlWB.shopRegByStore, this.options)
      this.visShopStatByStore = new tableau.Viz(this.$refs.shopStatByStore, this.urlWB.shopStatByStore, this.options)
      this.visShopStatuse = new tableau.Viz(this.$refs.shopStatus, this.urlWB.shopStatus, this.options)
      this.visCumulativeReg = new tableau.Viz(this.$refs.cumulativeReg, this.urlWB.cumulativeReg, this.options)
      this.visCumulativeParShop = new tableau.Viz(this.$refs.cumulativeParShop, this.urlWB.cumulativeParShop, this.options)
      this.visCumulativeParShopper = new tableau.Viz(this.$refs.cumulativeParShopper, this.urlWB.cumulativeParShopper, this.options)
    },
    exportExcelData: function() {
      debugger
      if (this.wsSelected == 'ShopRegByStore') {
        this.visShopRegByStore.showExportDataDialog()
      } else if (this.wsSelected == 'ShopStatByStore') {
        this.visShopStatByStore.showExportDataDialog()
      } else if (this.wsSelected == 'ShopStatus') {
        this.visShopStatuse.showExportDataDialog()
      } else if (this.wsSelected == 'CumuReg') {
        this.visCumulativeReg.showExportDataDialog()
      } else if (this.wsSelected == 'CumuParShop') {
        this.visCumulativeParShop.showExportDataDialog()
      } else if (this.wsSelected == 'CumuParStore') {
        this.visCumulativeParShopper.showExportDataDialog()
      }
      cash('#chooseWS').modal('hide')
    },
    exportToPDF: function() {
      this.viz.showExportPDFDialog()
    },
    exportToExcel: function() {
      this.viz.exportCrossTabToExcel()
    },
    exportImage: function() {
      this.viz.showExportImageDialog()
    },
    exportPowerPoint: function() {
      this.viz.showExportPowerPointDialog()
    },
    showDownloadDialog: function() {
      this.viz.showExportDataDialog()
    },
    exportCSV() {
      this.viz.showExportCrossTabDialog()
    }
  }
}
/* https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/ShopRegisterByStore?:showAppBanner=false&:origin=viz_share_link&:display_count=n&:showVizHome=n 
 */
/* https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/ShopStatusByStore?:showAppBanner=false&:origin=viz_share_link&:display_count=n&:showVizHome=n
 */
/* https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/ShopStatus?:showAppBanner=false&:origin=viz_share_link&:display_count=n&:showVizHome=n
  */
/* https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/CumulativeRegistration?:showAppBanner=false&:origin=viz_share_link&:display_count=n&:showVizHome=n
   */
/* https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/CumulativeParticipationshop?:showAppBanner=false&:origin=viz_share_link&:display_count=n&:showVizHome=n
  */
/* https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/CumulativeParticipationshopper?:showAppBanner=false&:origin=viz_share_link&:display_count=n&:showVizHome=n
 */
</script>
