<template>
  <div class="w-full sm:w-auto flex mt-1 sm:mt-1 mb-2" style="position: relative; float: right;">
                  <div class="flex mt-5">
                    <a href="javascript:;" data-toggle="modal" data-target="#chooseWS"  class="w-full mr-4 cursor-pointer">
                       <div class="w-6 h-6 flex-none image-fit">
                        <img
                          :src="require(`@/assets/images/tabIcon/Data Export icon.png`)"
                        />
                      </div>
                    </a>
                    <button
                     title="Download Image"  v-on:click="exportImage"
                      class="w-full mr-4 cursor-pointer"
                    >
                      <div class="w-6 h-6 flex-none image-fit">
                        <img
                          :src="require(`@/assets/images/tabIcon/Image icon.png`)"
                        />
                      </div>
                    </button>
                    <button
                       title="Download PDF" v-on:click="exportToPDF"
                      class="w-full mr-4 cursor-pointer"
                    >
                      <div class="w-6 h-6 flex-none image-fit">
                        <img
                          :src="require(`@/assets/images/tabIcon/PDF icon.png`)"
                        />
                      </div>
                    </button>
                    <button
                      title="Download Power point"  v-on:click="exportPowerPoint"
                      class="w-full mr-4 cursor-pointer"
                    >
                      <div class="w-6 h-6 flex-none image-fit">
                        <img
                          :src="require(`@/assets/images/tabIcon/powerpoint.svg`)"
                        />
                      </div>
                    </button>
                  </div>
                </div>
    <!-- END: Account Menu -->
    <div ref="tableau"></div>
    <div ref="CountProvince2" style="display:none;">
    </div>
    <div ref="TotalMember" style="display:none;">
    </div>
    <div ref="TotalShop" style="display:none;">
    </div>
    <div ref="TotalShopper" style="display:none;">
    </div>
    <div ref="Date" style="display:none;">
    </div>
    <div ref="Map" style="display:none;">
    </div>

<!-- END: Modal Toggle -->
<!-- BEGIN: Modal Content -->
<div id="chooseWS" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                    Choose Worksheet
                </h2>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body grid grid-cols-12 gap-20 gap-y-3">
                <div class="col-span-120 sm:col-span-6">
                    <label for="modal-form-6" class="form-label">ข้อมูลจาก Worksheet</label>
                    <select v-model="wsSelected" id="modal-form-6" class="form-select">
                        <option value="CountProvince2">Count Province 2</option>
                        <option value="TotalMember">Total Member (2)</option>
                        <option value="TotalShop">Total Shop(2)</option>
                        <option value="TotalShopper">Total Shopper (2)</option>
                        <option value="Date">Date</option>
                        <option value="Map">Map Province (2)</option>
                    </select>
                </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer text-right">
                <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-20 mr-1">
                    Close
                </button>
                <button type="button" class="btn btn-primary w-30"   v-on:click="exportExcelData">
                    Export Data
                </button>
            </div>
            <!-- END: Modal Footer -->
        </div>
    </div>
</div>
<!-- END: Modal Content -->
</template>

<script>

export default {
  name: 'Tableau Report 1',
  data() {
    return {
      wsSelected: '',
      url: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/RTDLuckyOverview/4f522678-2e38-4e21-9a9e-a39b6ab01262/08967191-c1e7-47e8-b2cd-cfe70e7b6fd5?:display_count=n&:showVizHome=n&:origin=viz_share_link',
      urlWB: {
        CountProvince2: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/CountProvince2?:origin=card_share_link&:embed=n',
        TotalMember: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/TotalMember2?:origin=card_share_link&:embed=n',
        TotalShop: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/TotalShop2?:origin=card_share_link&:embed=n',
        TotalShopper: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/TotalShopper2?:origin=card_share_link&:embed=n',
        Date: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/DATE?:origin=card_share_link&:embed=n',
        Map: 'https://prod-apnortheast-a.online.tableau.com/t/outlier/views/RTDLuckyDashboard-deploy/MapProvince2?:origin=card_share_link&:embed=n'
      },
      options: {
        hideTabs: true,
        onFirstInteractive: function() {
          console.log('Run this code when the viz has finished loading.')
        }
      }, 
      vis: {}
    }
  },
  mounted: function() {
    this.initViz()
  },
  methods: {
    initViz: function() {
      this.viz = new tableau.Viz(this.$refs.tableau, this.url, this.options)
      this.visCountProvince2 = new tableau.Viz(this.$refs.CountProvince2, this.urlWB.CountProvince2, this.options)
      this.visTotalMember = new tableau.Viz(this.$refs.TotalMember, this.urlWB.TotalMember, this.options)
      this.visTotalShop = new tableau.Viz(this.$refs.TotalShop, this.urlWB.TotalShop, this.options)
      this.visTotalShopper = new tableau.Viz(this.$refs.TotalShopper, this.urlWB.TotalShopper, this.options)
      this.visDate = new tableau.Viz(this.$refs.Date, this.urlWB.Date, this.options)
      this.visMap = new tableau.Viz(this.$refs.Map, this.urlWB.Map, this.options)
    },
    exportExcelData: function() {
      debugger
      if (this.wsSelected == 'CountProvince2') {
        this.visCountProvince2.showExportDataDialog()
      } else if (this.wsSelected == 'TotalMember') {
        this.visTotalMember.showExportDataDialog()
      } else if (this.wsSelected == 'TotalShop') {
        this.visTotalShop.showExportDataDialog()
      } else if (this.wsSelected == 'TotalShopper') {
        this.visTotalShopper.showExportDataDialog()
      } else if (this.wsSelected == 'Date') {
        this.visDate.showExportDataDialog()
      } else if (this.wsSelected == 'Map') {
        this.visMap.showExportDataDialog()
      } 
      cash('#chooseWS').modal('hide')
    },
    exportToPDF: function() {
      this.viz.showExportPDFDialog()
    },
    exportToExcel: function() {
      this.viz.exportCrossTabToExcel()
    },
    exportImage: function() {
      this.viz.showExportImageDialog()
    },
    exportPowerPoint: function() {
      this.viz.showExportPowerPointDialog()
    },
    showDownloadDialog: function() {
      this.viz.showExportDataDialog()
    },
    exportCSV() {
      this.viz.showExportCrossTabDialog()
    }
  }
}
</script>
